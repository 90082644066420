import React from 'react'
const ambulance = [
  {
    name: "Basic Life Support (BLS) Ambulance",
    description: "The Basic Life Support (BLS) ambulance service is intended to assist with the basic medical care of the patient during transportation. Our ambulances are staffed with trained medical professionals, and come equipped with oxygen, a defibrillator, and basic airway management tools to treat the full range of medical emergencies wherever they occur."
  },
  {
    name: "ICU Ambulance",
    description: "Our Best ICU Ambulance Service in Rajasthan and it is team are specially trained paramedics, nurses and doctors who have experience in the most complex medical situations. They work in teams to monitor and treat the patient's vital signs, airway, and other important medical functions."
  },
  {
    name: "Deadbody Ambulance",
    description: "We know this is a tender time when you need to have someone transported to their final resting place. This is why we offer sympathetic care and communicate directly with funeral homes and mortuary transportation services to ensure that everything is handled with dignity and compassion. Our focus is to help take the pressure off the family during this time of grief."
  },
  {
    name: "Bariatric Ambulance",
    description: "The bariatric ambulance service we offer has been designed for the safe, secure, and comfortable transport of larger or heavier patients who are in need of particular medical care. Our ambulances are equipped with hydraulic lifts and reinforced stretchers and our medical team is trained to transport and care for bariatric patients."
  },
  {
    name: "Pets Ambulance",
    description: "We also offer a pet ambulance service, which is great for moving sick pets to and from veterinary services, or relocating to another country with your beloved fur baby. Our ambulances are fitted with unique gear and staffed with pet-carer professionals who are experienced in dealing with all kinds of animals."
  },
  {
    name: "Outstation Ambulance",
    description: "The outstation ambulance service offers safe and reliable ground transport for patients who need to be moved over long distances. AMBULANCE INDIA outstation ambulances are well equipped with latest medical gadgets and are manned by dedicated trained medical attendants. They are capable of handling any kind of medical emergency that may be encountered during transit."
  },
  {
    name: "Air Ambulance",
    description: "Our air ambulance service can be booked easily by dialling our 24X7 dispatch centre and the team will coordinate with you for prompt and reliable arrangements. We assure transparent communication and no hidden costs so you can rest assured that your loved one is getting the best care at a fair cost."
  },
  {
    name: "Wheelchair Ambulance",
    description: "The wheelchair ambulance service offers safe and comfortable transport for patients who cannot sit or move comfortably by themselves. The ambulance machines are equipped with specially designed wheelchair ramps and restraints and the trained medical attendants are experienced in handling such cases."
  },
  {
    name: "Pediatric Ambulance",
    description: "The pediatric ambulance service offers specialized medical transportation for infants and children who need medical attention during transit. The ambulance machines are equipped with specially designed pediatric medical gear and the trained attendants are highly skilled to handle pediatric cases."
  }
];

const Services = () => {
  return (
    <main >
      {/* ==============================Section for image =============== */}
      <div className="container my-5">
        {/* <h2 class="text-center">Our Services</h2> */}
        {/*     
    <div className="row">
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="card">
          <img
            src="../images/ambulance 2.jpeg"
            className="card-img-top h-25"
            alt="Ambulance Service"
          />
          <div className="card-body">
            <h5 className="card-title">Ambulance Service</h5>
            <p className="card-text">
              We provide 24/7 ambulance services for medical emergencies. Our
              ambulances are equipped with advanced medical equipment and
              staffed by trained medical professionals.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="card">
          <img
            src="../images/ambulance 1.jpeg"
            className="card-img-top h-25"
            alt="Hospital Transfer"
          />
          <div className="card-body">
            <h5 className="card-title">Hospital Transfer</h5>
            <p className="card-text">
              We also provide hospital transfer services for patients who need
              to be transferred between hospitals or from their homes to the
              hospital.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="card">
          <img
            src="../images/ambulance 2.jpeg"
            className="card-img-top h-25"
            alt="Hospital Transfer"
          />
          <div className="card-body">
            <h5 className="card-title">Hospital Transfer</h5>
            <p className="card-text">
              We also provide hospital transfer services for patients who need
              to be transferred between hospitals or from their homes to the
              hospital.
            </p>
          </div>
        </div>
      </div>
    </div> */}

      </div>
      {/* <section className="services" style={{ margin: "1em auto" }}>
    <div className="container">
      <h2 className="text-center mb-4">Our Services</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Emergency Response</h5>
              <p className="card-text">
                We provide prompt and efficient emergency response services to
                ensure that our patients receive the care they need as quickly
                as possible.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Medical Transportation</h5>
              <p className="card-text">
                Our medical transportation services ensure that patients can get
                to and from appointments and treatments comfortably and safely.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Bariatric Ambulance</h5>
              <p className="card-text">
                We have specially equipped bariatric ambulances to provide safe
                and comfortable transportation for patients with limited
                mobility.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
      {/* ===========Discription section ========== */}
      {/* <section id="about-services" className="bg-light py-5">
    <div className="container">
      <h2 className="text-center mb-5">Partnership Services</h2>
      <div className="row">
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-ambulance fa-4x mb-3" />
              <h3 className="card-title">Emergency Response</h3>
              <p className="card-text">
                Our team is available 24/7 to respond to any medical emergency.
                We provide fast and reliable ambulance services to ensure you
                get the medical care you need as quickly as possible.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-stethoscope fa-4x mb-3" />
              <h3 className="card-title">Diagnostic Services</h3>
              <p className="card-text">Available soon</p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card text-center">
            <div className="card-body">
              <i className="fas fa-medkit fa-4x mb-3" />
              <h3 className="card-title">Medical Treatment</h3>
              <p className="card-text">
                We provide a variety of medical treatments, including emergency
                care, surgery, and rehabilitation. Our experienced healthcare
                professionals use the latest medical technology to ensure the
                best possible outcome for our patients.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section> */}
      {/* Types of Ambulances avilable */}
      <section className="ambulancetype">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex justify-content-around my-1">
              <h2 className="text-white text-capitalize ">
                types of ambulances available
              </h2>
            </div>
          </div>
          <div className="row">

            {ambulance.map((item, index) => (
              <div key={index} className="col-sm-6 col-md-4">
                <div className="card my-1">
                  <div className="card-body">
                    <h5 className="card-title">
                      <i className="fas fa-ambulance" />
                      {item.name}
                    </h5>
                    <p className="card-text" style={{ textAlign: "justify" }}>
                      {item.description}
                    </p>
                  </div>
                </div>
              </div>
            ))}

          
          </div>
        </div>
      </section>
    </main>

  )
}

export default Services
