import React from 'react';

const GoogleAd = () => {
  return (
    <>
    <div data-ad-client="pub-2343825218133494" data-ad-slot="DIRECT" data-ad-format="f08c47fec0942fa0">
     google.com, pub-2343825218133494, DIRECT, f08c47fec0942fa0
    </div>
  
    </>
  );
};

export default GoogleAd;
