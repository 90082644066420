import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from './Form';


const styles = `
  @keyframes opacityLoop {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .logo-img {
    animation: opacityLoop 1.75s infinite alternate; 
  }
`;

function FormModel() {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <>
      <style>{styles}</style> 
      <Modal show={show} onHide={handleClose} style={{ backgroundColor: "#000000cf" }}>
        <Modal.Header closeButton style={{ backgroundColor: "#ffffff4f" }}>
          <img style={{marginLeft:"39%"}}
            className=' logo-img'
            height={100}
            width={100}
            src={require('../images/logo.png')}
            alt='logo'
          />
        </Modal.Header>
        <Form />
      </Modal>
    </>
  );
}

export default FormModel;
