import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../css/index.css';
import "../css/App.css";
import "../css/style.css";
import "../css/responsive.css";
import { Helmet } from 'react-helmet';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "./Header";
import Services from "./Services";
import About from "./About";
import Footer from "./Footer";
import Contact from "./Contact";
import Hero from "./Hero";
import UserList from "./Dashboard/userlist";
import Form from "./Form";
import Login from "./login";
import SignUp from "./SignUp";
import UserDash from "./Dashboard/userDash";
import Demo from "./Demo";
import Map from "./Map";
import NotFound from "./NotFound";
import UserQuery from "./UserQuery";
import Policy from "./Policy";
import TAndC from "./TAndC";
import "react-toastify/dist/ReactToastify.css";
// import ForgotPassword from "./ForgotPassword";
// import { Query } from "mongoose";
import GoogleAd from "./ads";
import FormModel from "./FormModel";

function App() {
  let data = localStorage.getItem("id");
  let user = JSON.parse(data);

  const currentLocation = "bhopal";

  const ambulance = [
    {
      name: "Basic Life Support (BLS) Ambulance",
      description: "The Basic Life Support (BLS) ambulance service is intended to assist with the basic medical care of the patient during transportation. Our ambulances are staffed with trained medical professionals, and come equipped with oxygen, a defibrillator, and basic airway management tools to treat the full range of medical emergencies wherever they occur."
    },
    {
      name: "ICU Ambulance",
      description: "Our Best ICU Ambulance Service in Bhopal and it is team are specially trained paramedics, nurses and doctors who have experience in the most complex medical situations. They work in teams to monitor and treat the patient's vital signs, airway, and other important medical functions."
    },
    {
      name: "Deadbody Ambulance",
      description: "We know this is a tender time when you need to have someone transported to their final resting place. This is why we offer sympathetic care and communicate directly with funeral homes and mortuary transportation services to ensure that everything is handled with dignity and compassion. Our focus is to help take the pressure off the family during this time of grief."
    },
    {
      name: "Bariatric Ambulance",
      description: "The bariatric ambulance service we offer has been designed for the safe, secure, and comfortable transport of larger or heavier patients who are in need of particular medical care. Our ambulances are equipped with hydraulic lifts and reinforced stretchers and our medical team is trained to transport and care for bariatric patients."
    },
    {
      name: "Pets Ambulance",
      description: "We also offer a pet ambulance service, which is great for moving sick pets to and from veterinary services, or relocating to another country with your beloved fur baby. Our ambulances are fitted with unique gear and staffed with pet-carer professionals who are experienced in dealing with all kinds of animals."
    },
    {
      name: "Outstation Ambulance",
      description: "The outstation ambulance service offers safe and reliable ground transport for patients who need to be moved over long distances. AMBULANCE INDIA outstation ambulances are well equipped with latest medical gadgets and are manned by dedicated trained medical attendants. They are capable of handling any kind of medical emergency that may be encountered during transit."
    },
    {
      name: "Air Ambulance",
      description: "Our air ambulance service can be booked easily by dialling our 24X7 dispatch centre and the team will coordinate with you for prompt and reliable arrangements. We assure transparent communication and no hidden costs so you can rest assured that your loved one is getting the best care at a fair cost."
    },
    {
      name: "Wheelchair Ambulance",
      description: "The wheelchair ambulance service offers safe and comfortable transport for patients who cannot sit or move comfortably by themselves. The ambulance machines are equipped with specially designed wheelchair ramps and restraints and the trained medical attendants are experienced in handling such cases."
    },
    {
      name: "Pediatric Ambulance",
      description: "The pediatric ambulance service offers specialized medical transportation for infants and children who need medical attention during transit. The ambulance machines are equipped with specially designed pediatric medical gear and the trained attendants are highly skilled to handle pediatric cases."
    }
  ];

  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
               
                  {ambulance.map((item, index) => (
                    <Helmet key={index}>
                      <meta  name={`keywords ${index+1}`}  content={item.name} />
                      <meta name={`description ${index+1}`} content={item.description} />
                    </Helmet>
                  ))}
             
                <Helmet>
                  <title>Book Ambulance Medalert Life || Medalert Life Home Page</title>
                  <meta name="twitter:title" content="ambulance in bhopal" />
                  <meta name="twitter:description" content={`Ambulance in ${currentLocation}`} />
                  <meta name="twitter:description" content="24 Hours Ambulance Services in Bhopal. Find ✓Ambulance Services, ✓24 Hours Ambulance Service Number, ✓108 Ambulance Services, ✓Air Ambulance Services in Bhopal. Get Phone Numbers, Address, Reviews, Photos, Maps for top 24 Hours Ambulance Services near me in Bhopal on Medalert Life." />
                  <meta name="twitter:image" content="https://pbs.twimg.com/profile_images/1617231153542615040/Pl4gwqAC_200x200.jpg" />
                  <meta name="twitter:card" content="summary_large_image" />
                </Helmet>
                <FormModel />
                <div className="row col-md-12 mt-3">
                  <Form className='col-md-4' />
                  <Hero className='col-md-8' />
                </div>
                <Services />
                <Contact />
                <Map />
              </>
            }
          />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/services" element={<Services />} />
          <Route exact path="/contact" element={<><Contact /><Map /></>} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/signup" element={<SignUp />} />
          {user?.role === 1 ? (
            <Route exact path="/dashboard" element={<><UserList /><UserQuery /></>} />
          ) : (
            <Route exact path="/myprofile/:phone" element={<UserDash />} />
          )}
          <Route exact path="/demo" element={<Demo />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/policy" element={<Policy />} />
          <Route path="/tnc" element={<TAndC />} />
          {/* <Route path="/forgot" element={<ForgotPassword/>}/> */}
          <Route path="/ads.txt" element={<GoogleAd />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

console.clear();
export default App;
